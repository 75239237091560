import { colors } from 'configs'
import { Avatar, Button, Column, Icon, Popover, Row, Typography } from 'design-system'
import PopoverNewContact from '../PopoverNewContact'
import { useClickout } from 'hooks'
import { useState } from 'react'

const PopoverMessageContact = ({
  elementRef,
  open,
  setNewTicketModal,
  contacts,
  ...props
}) => {
  const [selectedContact, setSelectedContact] = useState(null)

  const { elementRef: newElementRef } = useClickout(false)

  return (
    <Popover ref={elementRef} open={open} width='275px' p='16px' {...props}>
      <Column>
        <Typography>Clique no contato para salvar na sua lista.</Typography>
      </Column>
      <Column mt='8px' width='100%' gap='8px' overflow='auto' height='220px' pr='5px'>
        {contacts?.map((contact, index) => (
          <Row
            key={index}
            p='8px'
            border={`1px solid ${colors.grey[50]}`}
            borderRadius='8px'
            alignItems='center'
            justifyContent='space-between'
            gap='8px'
            className='cursor-pointer'
            backgroundColor={
              selectedContact?.first_name === contact?.first_name ? 'grey.50' : 'white'
            }
            onClick={() => setSelectedContact(contact)}
          >
            <Avatar alt={contact?.first_name} />
            <Typography fontWeight='600' ellipsis notSelectable>{`${
              contact?.first_name || contact?.phones[0]?.phone || ''
            } ${contact.last_name || ''}`}</Typography>
            <Button color='secondary' variant='text'>
              <Icon icon='Plus' />
              Salvar
            </Button>
          </Row>
        ))}
      </Column>
      {selectedContact && (
        <PopoverNewContact
          setNewTicketModal={setNewTicketModal}
          open={selectedContact}
          contact={selectedContact}
          setOpenedState={setSelectedContact}
          elementRef={newElementRef}
          top='-165px'
          left='105%'
        />
      )}
    </Popover>
  )
}

export default PopoverMessageContact
