import { clientApi } from 'providers'
import { AxiosResponse } from 'axios'
import { ModuleAction, Role, RoleListParams, RoleModuleActionsParams } from './types'
import { PaginatedResponse } from 'types/generic.types'

export const showRoleInfo = (id: number): Promise<AxiosResponse<Role>> =>
  clientApi.get(`/roles/${id}/`)

export const showRolesList = (params: RoleListParams): Promise<AxiosResponse<PaginatedResponse<Role>>> =>
  clientApi.get('/roles/', { params })

export const showRolesPermissions = (params: RoleModuleActionsParams): Promise<AxiosResponse<PaginatedResponse<ModuleAction>>> =>
  clientApi.get('/roles/module-actions/', { params })

export const createRole = (data: Partial<Role>): Promise<AxiosResponse<Role>> =>
  clientApi.post('/roles/', data)

export const updatedRole = (id: number, data: Partial<Role>): Promise<AxiosResponse<Role>> =>
  clientApi.put(`/roles/${id}/`, data)

export const deleteRole = (id: number): Promise<AxiosResponse<void>> =>
  clientApi.delete(`/roles/${id}/`)