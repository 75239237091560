import { AxiosResponse } from 'axios';
import { clientApi } from 'providers';
import { ExtraField,ExtraFieldListParams } from './types';
import { PaginatedResponse } from '../../types/generic.types';

export const showExtraFieldInfo = (id: number): Promise<AxiosResponse<ExtraField>> => 
  clientApi.get(`/contacts/extra-fields/${id}/`);

export const showExtraFieldsList = (params: ExtraFieldListParams): Promise<AxiosResponse<PaginatedResponse<ExtraField>>> => 
  clientApi.get('/contacts/extra-fields/', { params });

export const createExtraField = (data: Partial<ExtraField>): Promise<AxiosResponse<ExtraField>> => 
  clientApi.post('/contacts/extra-fields/', data);

export const updatedExtraField = (id: number, data: Partial<ExtraField>): Promise<AxiosResponse<ExtraField>> => 
  clientApi.put(`/contacts/extra-fields/${id}/`, data);

export const deleteExtraField = (id: number): Promise<AxiosResponse<void>> => 
  clientApi.delete(`/contacts/extra-fields/${id}/`);