import React, { useState } from 'react'
import styled from 'styled-components'
import { space, layout, color, compose } from 'styled-system'

import Icon from '../Icon'
import Column from '../Column'

const styles = compose(space, layout, color)

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeef1;
  gap: 20px;
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};

  ${({ sticky }) =>
    sticky
      ? `
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1050;
  `
      : ''}

  ${styles}
`

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-weight: 400;
  color: ${({ theme: { colors } }) => colors.grey[300]} !important;
  transition: all 0.3s ease;
  & svg path {
    fill: ${({ theme: { colors } }) => colors.grey[300]};
  }

  &:hover {
    color: ${({ color, theme: { colors } }) => colors[color]} !important;
    border-bottom: ${({ color, theme: { colors } }) => `2px solid ${colors[color]}`};
    & svg path {
      fill: ${({ color, theme: { colors } }) => colors[color]};
    }
  }

  ${({ active, color, theme: { colors } }) =>
    active &&
    `
    font-weight: 600;
    color: ${colors[color]} !important;
    border-bottom: 2px solid ${colors[color]};
     & svg path {
        fill:  ${colors[color]};
     }
  `}

  ${styles}
`

const TabPanel = styled.div`
  margin-top: 12px;
  ${styles}
`

const Tabs = ({ tabs, defaultTab, color, ...props }) => {
  const [activeTab, setActiveTab] = useState(defaultTab || 0)

  return (
    <Column width='100%' overflow='auto'>
      <TabContainer {...props}>
        {tabs.map((tab, index) =>
          tab.action === 'close' ? (
            <Column key={index} alignItems='center' minWidth='55px'>
              <Icon
                m='8px'
                icon={tab?.icon || 'Close'}
                color={tab?.type}
                onClick={() => tab?.handler(false)}
                cursor='pointer'
              />
            </Column>
          ) : tab.disabled ? null : (
            <Tab
              key={index}
              active={activeTab === index}
              onClick={() => (tab.handler ? tab?.handler() : setActiveTab(index))}
              color={color}
            >
              {tab.icon && <Icon m='8px' icon={tab.icon} cursor='pointer' />}
              {tab.label && tab.label}
            </Tab>
          ),
        )}
      </TabContainer>
      {!tabs[activeTab].noContent && <TabPanel>{tabs[activeTab].content}</TabPanel>}
    </Column>
  )
}

Tab.defaultProps = {
  type: 'primary',
  color: 'primary',
  action: 'content',
  sticky: false,
}

export default Tabs
