import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Row,
  Card,
  Input,
  Form,
  Button,
  Typography,
  Table,
  Badge,
  Icon,
  Column,
} from 'design-system'
import { ModalConfirmation, ResetPasswordInfos } from 'components'

import { useAuth } from 'hooks'
import { notify, hexToRGBA } from 'helpers'
import { updateMe } from 'services'
import { inviteStatusDict } from 'configs'
import { showUserInvites, acceptUserInvite } from 'services'
import { useSettingsReducer, actionTypes } from './store'
import { format } from 'date-fns'
import { passwordRegex, removeMask } from 'utils'

const UserPreferences = () => {
  const [loading, setLoading] = useState(false)
  const { userData, updateLoggedUser, userActionTypes } = useAuth()
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [showPassword, setShowPassword] = useState({
    confirm_password: false,
    password: false,
    current_password: false,
  })
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previus: null,
    page_size: 50,
    total_pages: 1,
  })

  const [state, dispatch] = useSettingsReducer()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setError,
  } = useForm()

  const watchPassword = watch('password')

  useEffect(() => {
    const { email, name, dial_code } = userData.user

    getUserInvites()

    reset({ email, name, dial_code })

    return () => reset({})
  }, [userData, reset])

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      delete values.current_password
      delete values.password
      delete values.confirm_password

      values.dial_code = removeMask(values.dial_code)

      const { data } = await updateMe(values)

      updateLoggedUser({
        type: userActionTypes.UPDATE_ITEM,
        payload: { ...userData, user: { ...data.user, ...userData.user.settings } },
      })
      notify.success('Preferências salvas com sucesso!')
    } catch {
      notify.error('Não foi possível salvar preferências no momento.')
    } finally {
      setLoading(false)
    }
  }

  const onSubmitPassword = async (values) => {
    try {
      setLoading(true)

      if (!values.password) setError('password')

      delete values.name
      delete values.email
      delete values.dial_code

      await updateMe(values)

      reset({ password: '', confirm_password: '', current_password: '' })
      notify.success('Senha alterada com sucesso!')
    } catch (err) {
      notify.error(err?.response?.data?.detail || 'Não foi possível salvar senha no momento.')
    } finally {
      setLoading(false)
    }
  }

  const getUserInvites = async () => {
    try {
      setLoading(true)

      const { data } = await showUserInvites()

      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data?.results,
        tab: 'user_invites',
        filter: 'email',
      })
    } catch {
      notify.error('Não foi possível resgatar lista de convites.')
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateInviteStatus = async (values, status) => {
    try {
      setLoading(true)

      const { data } = await acceptUserInvite(values?.id, status)

      dispatch({
        type: actionTypes.UPDATE_ITEM,
        payload: data,
        tab: 'user_invites',
        filter: 'email',
      })
      setModalConfirmation(null)
      notify.success('Convite atualizado com sucesso.')
    } catch {
      notify.error('Não foi possível atualizar o convite no momento.')
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      header: 'Empresa',
      render: ({ company: { name } }) => <Typography>{name}</Typography>,
    },
    {
      header: 'Status',
      render: ({ status }) => (
        <Row gap='8px'>
          <Badge
            width='90px'
            backgroundColor={hexToRGBA(inviteStatusDict[status].color, 0.2)}
            color={inviteStatusDict[status].color}
          >
            {inviteStatusDict[status].label}
          </Badge>
        </Row>
      ),
    },
    {
      header: 'Data de expiração',
      render: ({ expiration_date }) => (
        <Typography>{format(expiration_date, 'dd/MM/yyyy')}</Typography>
      ),
    },
    {
      header: 'Ações',
      render: (row) =>
        row.status === 'pending' && (
          <Row gap='20px' width='100%'>
            <Icon
              cursor='pointer'
              icon='Check'
              color='success'
              onClick={() =>
                setModalConfirmation({
                  type: 'primary',
                  title: `Aceitar convite de ${row?.company?.name}.`,
                  message:
                    'Você realmente deseja aceitar o convite da empresa? Ao aceitá-lo, você estará vinculado a ela e poderá começar a acessar as informações disponíveis de acordo com seu cargo.',
                  handler: () =>
                    handleUpdateInviteStatus(row, {
                      status: 'accepted',
                    }),
                })
              }
            />
            <Icon
              cursor='pointer'
              icon='Close'
              color='danger'
              onClick={() =>
                setModalConfirmation({
                  type: 'danger',
                  title: `Rejeitar convite de ${row?.company?.name}.`,
                  message:
                    'Você realmente deseja rejeitar o convite da empresa? Caso o rejeite, não será possível aceitá-lo posteriormente, e a empresa não poderá enviar novos convites para o seu e-mail.',
                  handler: () =>
                    handleUpdateInviteStatus(row, {
                      status: 'declined',
                    }),
                })
              }
            />
          </Row>
        ),
    },
  ]

  return (
    <Column gap='16px'>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Typography mt='16px' fontWeight='600'>
            Configurar informações básicas do usuário
          </Typography>
          <Row mt='16px' maxWidth={['100%', '100%', '280px']}>
            <Input label='E-mail' error={errors?.email} {...register('email')} disabled readOnly />
          </Row>
          <Row mt='16px' maxWidth={['100%', '100%', '280px']}>
            <Input
              label='Nome'
              error={errors?.name}
              disabled={loading}
              {...register('name', { required: true })}
            />
          </Row>
          <Row mt='16px' maxWidth={['100%', '100%', '280px']}>
            <Input
              label='Telefone'
              name='dial_code'
              disabled={loading}
              {...register('dial_code')}
            />
          </Row>
          <Row maxWidth={['100%', '100%', '280px']} mt='16px'>
            <Button type='submit' disabled={loading}>
              Salvar
            </Button>
          </Row>
        </Form>
      </Card>
      <Card mt='16px'>
        <Form onSubmit={handleSubmit(onSubmitPassword)}>
          <Typography mt='16px' fontWeight='600'>
            Segurança
          </Typography>
          <Column mt='16px' gap='16px' maxWidth={['100%', '100%', '280px']}>
            <Input
              label='Senha atual'
              type={showPassword?.current_password ? 'text' : 'password'}
              placeholder='Digite senha atual'
              icon={showPassword?.current_password ? 'Eye' : 'ClosedEye'}
              error={errors?.current_password}
              errorMessage={errors?.current_password?.message}
              iconProps={{
                color: 'grey.100',
                onClick: () =>
                  setShowPassword((prevState) => ({
                    ...prevState,
                    current_password: !prevState?.current_password,
                  })),
              }}
              {...register('current_password')}
            />
            <Input
              label='Alterar senha'
              type={showPassword?.password ? 'text' : 'password'}
              placeholder='Digite nova senha'
              icon={showPassword?.password ? 'Eye' : 'ClosedEye'}
              error={errors?.password}
              errorMessage={errors?.password?.message}
              iconProps={{
                color: 'grey.100',
                onClick: () =>
                  setShowPassword((prevState) => ({
                    ...prevState,
                    password: !prevState?.password,
                  })),
              }}
              {...register('password', {
                pattern: {
                  value: passwordRegex,
                  message: 'Senha inválida',
                },
              })}
            />
            <Input
              label='Confirmar nova senha'
              placeholder='Digite a confirmação'
              type={showPassword?.confirm_password ? 'text' : 'password'}
              icon={showPassword?.confirm_password ? 'Eye' : 'ClosedEye'}
              error={errors?.confirm_password}
              errorMessage={errors?.confirm_password?.message}
              iconProps={{
                onClick: () =>
                  setShowPassword((prevState) => ({
                    ...prevState,
                    confirm_password: !prevState?.confirm_password,
                  })),
              }}
              {...register('confirm_password', {
                pattern: {
                  value: passwordRegex,
                  message: 'Senha inválida',
                },
                validate: (value) => {
                  if (value !== watchPassword) {
                    return 'As senhas precisam ser igual.'
                  }
                },
              })}
            />
          </Column>
          {(errors?.confirm_password || errors?.password) && <ResetPasswordInfos />}
          <Row maxWidth={['100%', '100%', '280px']} mt='16px'>
            <Button type='submit' disabled={loading}>
              Salvar nova senha
            </Button>
          </Row>
        </Form>
      </Card>
      <Card>
        <Typography mt='16px' variant='caption' fontWeight='600'>
          Lista de convites
        </Typography>
        <Table
          mt='24px'
          title='usuário'
          columns={columns}
          data={state.user_invites}
          pagination={pagination}
          setPagination={setPagination}
          emptyMessage='Parece que você não tem nenhum convite ainda. Caso tenha sido convidado para alguma empresa, verifique seu e-mail ou entre em contato com a empresa.'
        />
      </Card>
      <ModalConfirmation
        open={modalConfirmation}
        onClose={() => setModalConfirmation(null)}
        {...modalConfirmation}
      />
    </Column>
  )
}

export default UserPreferences
