import { AxiosResponse } from 'axios';
import { clientApi } from 'providers';
import {
  ConfirmResetPasswordData,
  LoginData,
  LoginResponse,
  ResetPasswordData,
  UserData
} from './types';


export const login = (data: LoginData): Promise<AxiosResponse<LoginResponse>> =>
  clientApi.post('/users/login/', data);

export const me = (): Promise<AxiosResponse<UserData>> =>
  clientApi.get('/users/me/');

export const requestResetPassword = (data: ResetPasswordData): Promise<AxiosResponse<void>> =>
  clientApi.post('/users/reset-password/', data);

export const resetPassword = (data: ConfirmResetPasswordData): Promise<AxiosResponse<void>> =>
  clientApi.post('/users/reset-password/confirm/', data);