import { AxiosResponse } from 'axios';
import { clientApi } from 'providers';
import {
  CompanySimpleData,
  CompanyInviteRequestData,
  CompanyParams,
  ShowCompaniesResponse,
  Company,
  CompanyInvite,
  InvitationListParams,
  ShowCompanyInviteResponse
} from './types';

export const showCompaniesList = (params: Partial<CompanyParams>): Promise<AxiosResponse<ShowCompaniesResponse>> =>
  clientApi.get('/companies/', { params });

export const updateCompanyInfo = (id: number, data: Partial<CompanySimpleData>): Promise<AxiosResponse<Company>> =>
  clientApi.put(`/companies/${id}/`, data);

export const updateCompanyInvite = (id: number, data: Partial<CompanyInviteRequestData>): Promise<AxiosResponse<CompanyInvite>> =>
  clientApi.put(`/companies/user-invites/${id}/`, data);

export const createCompanyInvite = (data: CompanyInviteRequestData): Promise<AxiosResponse<CompanyInvite>> =>
  clientApi.post('/companies/user-invites/', data);

export const showCompanyInviteList = (params: Partial<InvitationListParams>): Promise<AxiosResponse<ShowCompanyInviteResponse>> =>
  clientApi.get('/companies/user-invites/', { params });

export const generateNewCompanyUuid = (id: number): Promise<AxiosResponse<CompanySimpleData>> =>
  clientApi.post(`/companies/${id}/generate-new-uuid/`);