import { clientApi } from 'providers'
import { AxiosResponse } from 'axios'
import { PaginatedResponse, PaginationParams } from 'types/generic.types'
import { ReactionFlow } from './types'

export const showReactionsFlow = (params: PaginationParams): Promise<AxiosResponse<PaginatedResponse<ReactionFlow>>> =>
  clientApi.get('/reactive-messages/reaction-flows/', { params })

export const applyReactionsFlow = (id: number, data: Partial<ReactionFlow>): Promise<AxiosResponse<ReactionFlow>> =>
  clientApi.post(`/reactive-messages/reaction-flows/${id}/apply/`, data)

