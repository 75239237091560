import { clientApi } from 'providers';
import { AxiosResponse } from 'axios';
import { User } from '@sentry/react';
import { PaginatedResponse } from 'types/generic.types';
import { UserCompanyUserListParams, UserCompany, UserInviteListParams, UserCompanyUserSelect, UserCompanyUser, ShowCompanyUsersListSelectParams } from './type';
import { CompanyUser } from 'services/companies/types';

// USERS
export const updateMe = (data: Partial<User>): Promise<AxiosResponse<User>> =>
  clientApi.put(`/users/me/`, data);

export const changeUserCompany = (data: { company: number }): Promise<AxiosResponse<{ company: number }>> =>
  clientApi.post('/users/change-company/', data);

// USER INVITES
export const showUserInvites = (params: UserInviteListParams): Promise<AxiosResponse<PaginatedResponse<UserCompany>>> =>
  clientApi.get('/users/me/invites/', { params });

export const acceptUserInvite = (id: number, data: Partial<User>): Promise<AxiosResponse<User>> =>
  clientApi.put(`/users/me/invites/${id}/`, data);

// USER REGISTER
export const registerUser = (data: UserCompanyUser): Promise<AxiosResponse<UserCompanyUser>> =>
  clientApi.post('/users/register/', data);

export const confirmaUserEmail = (data: { confirmation_code: string }): Promise<AxiosResponse<{ confirmation_code: string }>> =>
  clientApi.put('/users/confirm-email/', data);

// USER COMPANY
export const showCompanyUsersList = (params: UserCompanyUserListParams): Promise<AxiosResponse<PaginatedResponse<CompanyUser>>> =>
  clientApi.get(`/users/company-users/`, { params });

export const showCompanyUsersListSelect = (params: ShowCompanyUsersListSelectParams): Promise<AxiosResponse<UserCompanyUserSelect>> =>
  clientApi.get(`/users/company-users/select/`, { params });

export const showCompanyUserInfo = (id: number): Promise<AxiosResponse<CompanyUser>> =>
  clientApi.get(`/users/company-users/${id}/`);

export const createCompanyUser = (data: Partial<CompanyUser>): Promise<AxiosResponse<CompanyUser>> =>
  clientApi.post('/users/company-users/', data);

export const updatedCompanyUser = (id: number, data: Partial<CompanyUser>): Promise<AxiosResponse<CompanyUser>> =>
  clientApi.put(`/users/company-users/${id}/`, data);

export const deleteCompanyUser = (id: number): Promise<AxiosResponse<void>> =>
  clientApi.delete(`/users/company-users/${id}/`);