import { useNavigate } from 'react-router-dom'

import { Avatar, Badge, ColorChip, Column, Row, Timer, Tooltip, Typography } from 'design-system'

import { colors, platformTypeDict } from 'configs'
import lastMessageDict from './lastMessageDict'

import { hexToRGBA, notify } from 'helpers'

import { useAuth } from 'hooks'

const TicketCard = ({ ticket, ...props }) => {
  const navigate = useNavigate()
  const {
    userData: { company_user },
  } = useAuth()

  const { solved_status, department, attendant, id, contact, closed_at, last_message } = ticket || {}

  const showSolvedStatus = !closed_at ? !solved_status : false

  const handleClick = () => {
    const canNavigate =
      company_user?.owner ||
      !department ||
      department?.public_tickets ||
      company_user?.departments.some((dp) => dp.id === department?.id) ||
      attendant === null

    if (canNavigate) {
      navigate(`/tickets/${id}`)
    } else {
      notify.warning('Você não pode acessar o atendimento de outro atendente.')
    }
  }

  return (
    <Row
      p='8px'
      borderRight='none'
      borderTop='1px solid #E8E8E8'
      borderLeft={`5px solid ${department ? department?.color : '#E8E8E8'}`}
      borderRadius='8px 0 0 8px'
      gap='16px'
      className='cursor-pointer'
      onClick={handleClick}
      {...props}
    >
      <Column>
        <Avatar size='md' square src={contact?.picture} alt={contact?.name} badge={platformTypeDict['whatsapp']?.logo} />
      </Column>
      <Column width='100%'>
        <Column>
          <Row justifyContent='space-between'>
            <Tooltip title={`${contact?.name} | ${contact?.dial_code}`}>
              <Typography ellipsis fontSize='14px' fontWeight={showSolvedStatus ? '600' : '400'} width='150px'>
                {contact?.name}
              </Typography>
            </Tooltip>

            {attendant && (
              <Badge
                width='100px'
                backgroundColor={colors.purple[50]}
                color={colors.purple[400]}
                title={attendant?.name}
                fontStyle={{ fontSize: '12px' }}
              />
            )}
          </Row>
          {lastMessageDict[last_message?.message_type || 'text'](ticket, showSolvedStatus)}
          <Row width='100%' mt='4px' justifyContent='flex-end' alignItems='center'>
            <Badge backgroundColor={hexToRGBA('#E8E8E8', 0.2)}>
              <Typography fontSize='12px' fontStyle='italic' color='grey.100' fontWeight={showSolvedStatus ? '600' : '400'}>
                {closed_at && `Encerrado `}
                <Timer initialTime={closed_at ? closed_at : last_message?.created_at} />
              </Typography>
              {showSolvedStatus && <ColorChip color='#136CDC' width='10px' height='10px' />}
            </Badge>
          </Row>
        </Column>
      </Column>
    </Row>
  )
}

export default TicketCard
