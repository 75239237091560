import { addDays } from 'date-fns'

const isOutsideWorkHours = (workSchedule) => {
  const now = addDays(new Date(), -1)
  const weekday = now.getDay()
  const currentTime = now.toTimeString().split(' ')[0].substring(0, 5)

  if (workSchedule.length === 0) return true

  const userWorkHours = workSchedule.filter((work) => work.weekday === weekday)

  if (userWorkHours.length === 0) {
    return true
  }

  const isOutside = userWorkHours.every(({ start, end }) => {
    return currentTime < start || currentTime > end
  })

  return isOutside
}

export default isOutsideWorkHours
