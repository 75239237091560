import { Icon, Row, Spinner, Tooltip, Typography } from 'design-system'

const SendButton = ({ loading, onSubmit, disabled }) =>
  loading ? (
    <Row alignItems='center' mr='16px'>
      <Typography whiteSpace='nowrap' mr='15px'>
        Enviando...
      </Typography>
      <Spinner size='sm' />
    </Row>
  ) : (
    <Tooltip position='top' title={!disabled && 'Pressione Enter para enviar'} width='120px'>
      <Icon
        disabled={loading}
        icon='SendButton'
        size='lg'
        color={disabled ? 'grey.50' : 'grey.300'}
        direction='right'
        {...(!disabled && {
          onClick: onSubmit,
          cursor: 'pointer',
        })}
      />
    </Tooltip>
  )

export default SendButton
