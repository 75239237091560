import React, { memo } from 'react'

const DefaultArrow = (props) => (
  <svg height='20' width='20' viewBox='0 0 20 20' {...props}>
    <mask x='0' y='0' width='24' height='24'>
      <rect width='24' height='24' fill='#D9D9D9' />
    </mask>
    <g>
      <path
        d='M12 20.9996L7.5 16.4996L8.95 15.0496L12 18.0996L15.05 15.0496L16.5 16.4996L12 20.9996ZM8.95 9.04961L7.5 7.59961L12 3.09961L16.5 7.59961L15.05 9.04961L12 5.99961L8.95 9.04961Z'
        fill='#1C1B1F'
      />
    </g>
  </svg>
)

const OrderArrowActiveUp = ({ ...props }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <mask id='mask0_4283_25013' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
      <rect width='24' height='24' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_4283_25013)'>
      <path
        d='M12 21.0001L7.5 16.5001L8.95 15.0501L12 18.1001L15.05 15.0501L16.5 16.5001L12 21.0001ZM8.95 9.0501L7.5 7.6001L12 3.1001L16.5 7.6001L15.05 9.0501L12 6.0001L8.95 9.0501Z'
        fill='#7F7F7F'
      />
      <path
        d='M15.0001 15L17.0071 16.4641L12 21L7.45213 16.4491L8.99381 15.0016L12.0001 18L13.6971 16.4491L15.0001 15Z'
        fill='#5E54A9'
      />
    </g>
  </svg>
)

const OrderArrowActiveDown = ({ ...props }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <mask id='mask0_4283_25013' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
      <rect width='24' height='24' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_4283_25013)'>
      <path
        d='M12 21.0001L7.5 16.5001L8.95 15.0501L12 18.1001L15.05 15.0501L16.5 16.5001L12 21.0001ZM8.95 9.0501L7.5 7.6001L12 3.1001L16.5 7.6001L15.05 9.0501L12 6.0001L8.95 9.0501Z'
        fill='#7F7F7F'
      />
      <path
        d='M8.8499 8.95L7.3999 7.5L11.8999 3L16.3999 7.5L14.9499 8.95L11.8999 5.9L8.8499 8.95Z'
        fill='#5E54A9'
      />
    </g>
  </svg>
)

const OrderArrow = (props) => {
  const dict = {
    active_up: (props) => <OrderArrowActiveUp {...props} />,
    active_down: (props) => <OrderArrowActiveDown {...props} />,
    default: (props) => <DefaultArrow {...props} />,
  }

  return dict[props?.active || 'default'](props)
}
const Memo = memo(OrderArrow)
export default Memo
