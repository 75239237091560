import { AxiosResponse } from 'axios';
import { clientApi } from 'providers';
import { Connection, ConnectionParams, ConnectionSelect } from './types';

export const showConnectionInfo = (id: number): Promise<AxiosResponse<Connection>> =>
  clientApi.get(`/connections/${id}/`);

export const showConnectionsList = (params: Partial<ConnectionParams>): Promise<AxiosResponse<Connection[]>> =>
  clientApi.get('/connections', { params });

export const showConnectionsListSelect = (params: {
  page_size: number
  active: boolean
}): Promise<AxiosResponse<ConnectionSelect>> =>
  clientApi.get('/connections/select', { params });

export const createConnection = (data: Partial<Connection>): Promise<AxiosResponse<Connection>> =>
  clientApi.post('/connections/', data);

export const updatedConnection = (id: number, data: Partial<Connection>): Promise<AxiosResponse<Connection>> =>
  clientApi.put(`/connections/${id}/`, data);

export const deleteConnection = (id: number): Promise<AxiosResponse<void>> =>
  clientApi.delete(`/connections/${id}/`);
