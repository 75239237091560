import { useEffect, useState } from 'react'

import {
  Column,
  Row,
  Button,
  Icon,
  Card,
  Table,
  ColorChip,
  Typography,
  WeekDaysSelector,
} from 'design-system'
import { PageHeader, ModalConfirmation, ModalDepartments, ProtectedComponent } from 'components'

import { deleteDepartment, showDepartmentsList } from 'services'

import { useDepartmentsReducer, actionTypes } from './store'
import { notify, withAuthorization } from 'helpers'

const Departments = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previus: null,
    page_size: 50,
    total_pages: 1,
  })
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [state, dispatch] = useDepartmentsReducer()

  useEffect(() => {
    fetch()

    
  }, [pagination?.current_page])

  const fetch = async (searchParam) => {
    try {
      setLoading(true)
      const { data } = await showDepartmentsList(pagination, searchParam)
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data.results,
      })

      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar lista de departamentos.')
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteDepartment(id)
      dispatch({
        type: actionTypes.REMOVE_ITEM,
        payload: id,
      })
      notify.success('Departamento excluído com sucesso.')
      setModalConfirmation(null)
    } catch {
      notify.error('Não foi possível excluir o departamento.')
    }
  }

  const columns = [
    {
      header: 'Ativo',
      render: ({ active }) => (
        <ColorChip
          color={active ? 'success' : 'danger'}
          borderRadius='100%'
          width='24px'
          height='24px'
        />
      ),
    },
    {
      header: 'Nome',
      field: 'name',
    },
    {
      header: 'Destaque',
      render: ({ color }) => <ColorChip color={color} />,
    },
    {
      header: 'Mensagem de saudação',
      render: ({ greeting_message }) => (
        <Typography
          maxWidth='200px'
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          wordBreak='break-word'
        >
          {greeting_message}
        </Typography>
      ),
    },
    {
      header: 'Mensagem de ausência',
      render: ({ away_message }) => (
        <Typography
          maxWidth='200px'
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          wordBreak='break-word'
        >
          {away_message}
        </Typography>
      ),
    },
    {
      header: 'Mensagem de finalização',
      field: 'message',
      render: ({ farewell_message }) => (
        <Typography
          maxWidth='200px'
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          wordBreak='break-word'
        >
          {farewell_message}
        </Typography>
      ),
    },
    {
      header: 'Dias de trabalho',
      render: ({ working_schedules }) => <WeekDaysSelector working_schedules={working_schedules} />,
    },
    {
      header: 'Ações',
      render: (row) => (
        <ProtectedComponent allowedRoles={['manage_departments']} unauthorizedComponent={false}>
          <Row gap='20px'>
            <Icon cursor='pointer' icon='Edit' color='primary' onClick={() => setModal(row)} />
            <Icon
              cursor='pointer'
              icon='Trash'
              color='danger'
              onClick={() =>
                setModalConfirmation({
                  type: 'danger',
                  title: `Excluir departamento ${row?.name}.`,
                  message:
                    'Deseja realmente excluir o departamento? Ele será removido de todos os usuários aos quais está atribuído, e esta ação é irreversível. Você pode inativar o departamento para evitar que ele seja atribuído a novos usuários.',
                  handler: () => handleDelete(row.id),
                })
              }
            />
          </Row>
        </ProtectedComponent>
      ),
    },
  ]

  return (
    <Column p='24px'>
      <PageHeader title='Departamentos'>
        <ProtectedComponent allowedRoles={['manage_departments']} unauthorizedComponent={false}>
          <Row gap='8px' justifyContent='flex-end'>
            <Button variant='icon' color='primary' onClick={() => setModal(true)}>
              <Icon icon='Add' />
            </Button>
          </Row>
        </ProtectedComponent>
      </PageHeader>
      <Card mt='16px' overflow='auto'>
        <Table
          title='departamento'
          columns={columns}
          loading={loading}
          data={state.departments}
          pagination={pagination}
          setPagination={setPagination}
          emptyMessage='Você ainda não tem departamentos, adicione para começar a organizar seus usuários!'
          handleEmptyData={() => setModal(true)}
        />
      </Card>
      <ModalDepartments
        department={modal}
        onClose={() => setModal(null)}
        dispatch={dispatch}
        actionTypes={actionTypes}
      />

      <ModalConfirmation
        open={modalConfirmation}
        onClose={() => setModalConfirmation(null)}
        {...modalConfirmation}
      />
    </Column>
  )
}

export default withAuthorization(Departments, ['access_departments'])
