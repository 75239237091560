import React, { forwardRef } from 'react'
import SelectLib from 'react-select/async'
import styled from 'styled-components'
import { space, layout, compose } from 'styled-system'
import { Controller } from 'react-hook-form'

import Typography from '../Typography'
import { colors } from 'configs'

const styles = compose(space, layout)

const circle = (color = 'transparent') => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  gap: '4px',
  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    height: 10,
    width: 10,
  },
})

const Base = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  ${styles}
`

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: '20px',
    border: `1px solid ${state.isFocused ? colors.blue[80] : colors.grey[50]}`,
    padding: '0px',
    color: colors.grey[300],
    fontSize: '14px',

    '&:hover': {
      borderColor: colors.blue[80],
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0px 8px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '1.7px 8px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '1050',
    width: 'max-content',
    minWidth: '100%',
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: colors.grey[300],
    ...circle(data?.color),
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    color: colors.grey[300],
    ...circle(data?.color),
  }),
}

const SelectAsync = forwardRef(
  (
    {
      label,
      maxWidth,
      keys,
      name,
      control,
      defaultValue,
      error,
      errorMessage,
      required,
      rules,
      callBack,
      ...props
    },
    ref,
  ) => {
    const handleChange = (data, field) => {
      field && field?.onChange(data)
      callBack && callBack(data)
      return data
    }

    return (
      <Base maxWidth={maxWidth}>
        {label && <Typography>{`${label} ${required ? '*' : ''}`}</Typography>}
        {control ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ required, ...rules }}
            render={({ field }) => (
              <SelectLib
                ref={ref}
                loadingMessage={() => 'Carregando...'}
                noOptionsMessage={() => 'Nenhum resultado encontrado.'}
                styles={selectStyles}
                getOptionLabel={(data) => data[keys.label]}
                getOptionValue={(data) => data[keys.value]}
                {...props}
                {...field}
                onChange={(data) => handleChange(data, field)}
              />
            )}
          />
        ) : (
          <SelectLib
            ref={ref}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => 'Nenhum resultado encontrado.'}
            styles={selectStyles}
            defaultValue={defaultValue}
            getOptionLabel={(data) => data[keys.label]}
            getOptionValue={(data) => data[keys.value]}
            {...props}
          />
        )}
        {error && (
          <Typography variant='helper' color='danger'>
            {errorMessage || 'Campo obrigatório'}
          </Typography>
        )}
      </Base>
    )
  },
)

SelectAsync.defaultProps = {
  options: [],
  placeholder: 'Selecione',
  keys: { label: 'label', value: 'value' },
  callBack: null,
}

export default SelectAsync
