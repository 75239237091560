import { memo } from 'react'

const Forward = (props) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" {...props}>
      <mask x="0" y="0" width="18" height="19">
      <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g>
      <path d="M3.70347 4.29653L3.73001 7.29641C3.73554 7.92138 3.95898 8.45068 4.40034 8.88429C4.84169 9.3179 5.37485 9.53194 5.99983 9.52641L12.8808 9.46553L10.157 6.78952L11.2164 5.73011L15.7561 10.1901L11.2961 14.7298L10.2181 13.6893L12.8941 10.9655L6.0131 11.0264C4.97564 11.0355 4.08807 10.6777 3.35037 9.953C2.61268 9.22824 2.23925 8.34714 2.23007 7.30968L2.20353 4.3098L3.70347 4.29653Z" fill="#656565" />
      </g>
    </svg>
  )
}
const Memo = memo(Forward)
export default Memo
