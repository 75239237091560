import { useEffect, useState } from 'react'

import { Column, Row, Button, Icon, Card, Table, ColorChip } from 'design-system'
import {
  PageHeader,
  ModalConfirmation,
  ModalRoles,
  ProtectedComponent,
  RobotHelp,
} from 'components'

import { deleteRole, showRolesList } from 'services'
import { actionTypes, useSettingsReducer } from './store'
import { notify, withAuthorization } from 'helpers'

const Roles = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previus: null,
    page_size: 50,
    total_pages: 1,
  })
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const [state, dispatch] = useSettingsReducer()

  useEffect(() => {
    fetch()

    
  }, [pagination?.current_page])

  const fetch = async () => {
    try {
      setLoading(true)
      const { data } = await showRolesList(pagination)
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data.results,
        tab: 'roles',
      })

      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar lista de cargos.')
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteRole(id)

      dispatch({
        type: actionTypes.REMOVE_ITEM,
        payload: id,
        tab: 'roles',
      })
      notify.success('Cargo excluído com sucesso.')
      setModalConfirmation(null)
    } catch {
      notify.error('Não foi possível excluir o cargo no momento.')
    }
  }

  const columns = [
    {
      header: 'Nome',
      field: 'name',
      cellProps: { width: '40%' },
    },
    {
      header: 'Destaque',
      cellProps: { width: '40%' },
      render: ({ color }) => <ColorChip color={color} />,
    },
    {
      header: 'Ações',
      cellProps: { width: '20%' },
      render: (row) => (
        <ProtectedComponent allowedRoles={['manage_roles']}>
          <Row gap='20px' width='100%'>
            <Icon
              cursor='pointer'
              icon='Edit'
              color='primary'
              onClick={() => setModal({ ...row, blocked: row.id === 1 || row.id === 2 })}
            />
            {(row?.id !== 1 || row?.id !== 2) && (
              <Icon
                cursor='pointer'
                icon='Trash'
                color='danger'
                onClick={() =>
                  setModalConfirmation({
                    type: 'danger',
                    title: `Excluir cargo ${row?.name}.`,
                    message:
                      'Você realmente deseja excluir este cargo? Ao excluir o cargo, ele será removido dos usuários aos quais está atrelado, e os mesmos perderão os acessos vinculados ao cargo.',
                    handler: () => handleDelete(row.id),
                  })
                }
              />
            )}
          </Row>
        </ProtectedComponent>
      ),
    },
  ]

  return (
    <Column>
      <Card>
        <PageHeader title='Gerenciamento de cargos'>
          <ProtectedComponent allowedRoles={['manage_roles']} unauthorizedComponent={false}>
            <Row gap='8px' justifyContent='flex-end'>
              <Button variant='icon' color='primary' onClick={() => setModal(true)}>
                <Icon icon='Add' />
              </Button>
            </Row>
          </ProtectedComponent>
        </PageHeader>
        <Card mt='16px' overflow='auto'>
          <Table
            title='cargos'
            columns={columns}
            loading={loading}
            data={state.roles.sort((itemA, itemB) => itemA?.name?.localeCompare(itemB?.name))}
            pagination={pagination}
            setPagination={setPagination}
            emptyMessage='Ops. Parece que você ainda não criou cargos. Crie cargos para personalizar os acessos dos seus usuários e criar uma configuração flexível para cada um.'
            handleEmptyData={() => setModal(true)}
          />
        </Card>
        {modal && (
          <ModalRoles
            role={modal}
            onClose={() => setModal(null)}
            dispatch={dispatch}
            actionTypes={actionTypes}
          />
        )}
        {modalConfirmation && (
          <ModalConfirmation
            open={modalConfirmation}
            onClose={() => setModalConfirmation(null)}
            {...modalConfirmation}
          />
        )}
      </Card>
      <RobotHelp
        mt='16px'
        maxWidth='580px'
        text='Crie e gerencie novos cargos para personalizar o nível de acesso de seus usuários. Você pode configurar desde o acesso apenas para visualização de informações até a permissão completa para adicionar, editar ou excluir informações.'
      />
    </Column>
  )
}

export default withAuthorization(Roles, ['access_roles'])
