import { clientApi } from 'providers'
import { AxiosResponse } from 'axios'
import { PaginatedResponse } from '../../types/generic.types'
import { MessageTemplate, MessageTemplateFetch, MessageTemplateParams, MessageTemplateSelect, MessageTemplateTagsParams, Tag } from './types'

// #TEMPLATES
export const showTemplatesList = (params: MessageTemplateParams): Promise<AxiosResponse<PaginatedResponse<MessageTemplate>>> => 
  clientApi.get(`/message-templates/`, { params })

export const showTemplatesListSelect = (): Promise<AxiosResponse<MessageTemplateSelect>> =>
  clientApi.get(`/message-templates/select`)

export const showTemplateInfo = (id: number): Promise<AxiosResponse<MessageTemplate>> => 
  clientApi.get(`/message-templates/${id}/`)

export const createTemplate = (data: Partial<MessageTemplate>): Promise<AxiosResponse<MessageTemplate>> => 
  clientApi.post(`/message-templates/`, data)

export const updateTemplate = (id: number, data: Partial<MessageTemplate>): Promise<AxiosResponse<MessageTemplate>> => 
  clientApi.put(`/message-templates/${id}/`, data)

export const refreshTemplate = (id: number): Promise<AxiosResponse<MessageTemplate>> => 
  clientApi.put(`/message-templates/${id}/refresh/`)

export const importTemplateUuid = (data: Partial<MessageTemplateFetch>): Promise<AxiosResponse<MessageTemplateFetch>> => 
  clientApi.post(`/message-templates/fetch/`, data)

export const deleteSoftTemplate = (id: number): Promise<AxiosResponse<void>> => 
  clientApi.delete(`/message-templates/${id}/soft/`)

// #TAGS
export const showTemplateTagsList = (params: MessageTemplateTagsParams): Promise<AxiosResponse<PaginatedResponse<Tag>>> =>
  clientApi.get(`/message-templates/tags/`, { params })

export const showTemplateTag = (id: number): Promise<AxiosResponse<Tag>> => 
  clientApi.get(`/message-templates/tags/${id}/`)

export const createTemplateTag = (data: Partial<Tag>): Promise<AxiosResponse<Tag>> => 
  clientApi.post(`/message-templates/tags/`, data)

export const updateTemplateTag = (id: number, data: Partial<Tag>): Promise<AxiosResponse<Tag>> => 
  clientApi.put(`/message-templates/tags/${id}/`, data)

export const deleteTemplateTag = (id: number): Promise<AxiosResponse<void>> => 
  clientApi.delete(`/message-templates/tags/${id}/`)