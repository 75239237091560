import { useEffect, useState } from 'react'

import { Row, Button, Image, Typography, Column, Icon, Spinner } from 'design-system'

import { confirmaUserEmail } from 'services'

import { chatGDSLogo1 } from 'assets'
import { useNavigate, useParams } from 'react-router-dom'
import { notify } from 'helpers'

const ConfirmEmail = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    params.confirmation_code ? handleConfirmEmail(params.confirmation_code) : setError(true)
  }, [params])

  const handleConfirmEmail = async (confirmation_code) => {
    try {
      setLoading(true)

      await confirmaUserEmail({ confirmation_code })
    } catch (err) {
      setError(true)

      if (err?.response?.data?.detail.length > 0)
        return notify.error(err?.response?.data?.detail[0])

      notify.error('Não foi possível confirmar seu e-mail. Tente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row
      alignItems='center'
      justifyContent='center'
      height='100%'
      p='20px'
      minHeight='100vh'
      backgroundColor='rgb(99,81,172)'
      backgroundImage='linear-gradient(90deg, rgba(17,23,42,1) 0%, rgba(99,81,172,1) 52%, rgba(17,23,42,1) 95%)'
    >
      {loading ? (
        <Column
          justifyContent='center'
          alignItems='center'
          border='1px solid #E8E8E8'
          borderRadius='5px'
          width='380px'
          height='380px'
          backgroundColor='#fff'
        >
          <Spinner size='lg' />
        </Column>
      ) : (
        <Column
          alignItems='center'
          maxWidth='380px'
          p='30px'
          border='1px solid #E8E8E8'
          borderRadius='5px'
          backgroundColor='#fff'
        >
          <Image src={chatGDSLogo1} width='200px' />
          {error ? (
            <>
              <Typography textAlign='center'>
                Parece que houve um problema na sua validação. Por favor, entre em contato com nossa
                equipe de suporte.
              </Typography>
            </>
          ) : (
            <>
              <Icon icon='Check' size='xlg' color='success' />
              <Typography mt='16px' textAlign='center'>
                Processo de autorização realizado com sucesso! Agora você pode acessar sua conta.
              </Typography>
              <Button mt='16px' onClick={() => navigate('/settings')}>
                Acesse sua conta aqui
              </Button>
            </>
          )}
        </Column>
      )}
    </Row>
  )
}

export default ConfirmEmail
