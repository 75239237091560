import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
  Typography,
  ModalFooter,
  SelectAsync,
  Select,
  Card,
  Icon,
} from 'design-system'

import { showDepartmentsListSelect, showCompanyUsersListSelect, updateTicket } from 'services'
import { notify } from 'helpers'
import { colors } from 'configs'
import { useNavigate } from 'react-router-dom'

const ModalAcceptTicket = ({
  onClose,
  ticket,
  dispatch,
  actionTypes,
  loading,
  ...props
}) => {
  const [attendants, setAttendants] = useState([])
  const [error, setError] = useState(null)

  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { active: true } })

  const getUserOptions = useCallback(
    async (departmentId) => {
      try {
        const { data } = await showCompanyUsersListSelect({
          page_size: 100,
          active: true,
          departments: departmentId,
        })

        setValue('attendant', data.results.find((i) => i.id === ticket?.attendant?.id) || null)
        setAttendants(data.results)
      } catch {
        notify.error('Não foi possível resgatar lista de departamentos.')
      }
    },
    [setValue, ticket],
  )

  useEffect(() => {
    if (ticket?.department) {
      getUserOptions(ticket.department.id)
    }
  }, [ticket, getUserOptions])

  const onSubmit = async (values) => {
    try {
      const updatedValues = {
        ...values,
        attendant: values.attendant.id,
        department: values.department.id,
        status: 'attending',
      }

      const { data } = await updateTicket(ticket.id, updatedValues)

      dispatch({
        type: actionTypes.UPDATE_SELECTED_TICKET,
        payload: data,
      })

      notify.success('Conversa atribuída com sucesso.')
      onClose()
    } catch (e) {
      const errorMsg =
        e?.response?.data?.detail[0] || 'Não foi possível salvar configuração da etiqueta.'
      setError(e.response.data)
      notify.error(errorMsg)
    }
  }

  const getDepartmentsOptions = async () => {
    const { data } = await showDepartmentsListSelect({ page_size: 100, active: true })
    return data.results
  }

  return (
    <Modal size='sm' open={!!ticket} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          Atribuir Responsável
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row width='100%'>
            <SelectAsync
              label='Departamento'
              cacheOptions
              defaultOptions
              loadOptions={getDepartmentsOptions}
              defaultValue={ticket?.department}
              keys={{ label: 'name', value: 'id' }}
              name='department'
              control={control}
              error={errors?.department}
              callBack={(data) => getUserOptions(data.id)}
              required
            />
          </Row>
          <Row mt='16px' width='100%'>
            <Select
              label='Atendente responsável'
              options={attendants}
              defaultValue={ticket?.attendant}
              keys={{ label: 'name', value: 'id' }}
              name='attendant'
              control={control}
              error={errors?.attendant}
              required
            />
          </Row>
          {error?.detail && (
            <Card
              mt='16px'
              p='12px'
              backgroundColor='grey.50'
              border={`1px solid ${colors.orange[400]}`}
              borderLeft={`5px solid ${colors.orange[400]}`}
              justifyContent='center'
            >
              <Row gap='8px'>
                <Icon icon='Alert' color='grey.300' />
                <Typography>{error.detail[0]}</Typography>
              </Row>
              {error?.ticket_id?.length > 0 && (
                <Row justifyContent='flex-end'>
                  <Typography
                    color='blue.80'
                    fontWeight='600'
                    cursor='pointer'
                    onClick={() => {
                      navigate(`/tickets/${error.ticket_id[0]}`)
                      onClose()
                    }}
                  >
                    Acessar conversa
                  </Typography>
                </Row>
              )}
            </Card>
          )}
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.300' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalAcceptTicket
