import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Column, Row } from 'design-system'
import TicketHeader from 'components/specific/tickets/TicketHeader'
import HeaderSelectedMessage from 'components/specific/tickets/TicketHeader/HeaderSelectedMessage'
import TicketMessage from 'components/specific/tickets/TicketMessage'
import TicketContactDrawer from 'components/specific/tickets/TicketContactDrawer'
import { ModalExpandImage } from 'components'

import { formatDateLabel, notify } from 'helpers'

import {
  markMessageRead,
  showTicketInfo,
  showTicketMessagesList,
  updateTicket,
  forwardMessages
} from 'services'
import CloseTicketHeader from './CloseTicketHeader'
import { ModalAcceptTicket } from 'components'
import SkeletonMessages from './SkeletonMessages'

import { useTickets } from 'hooks'
import CustomMessage from './CustomMessage'
import { format } from 'date-fns'
import TicketMessageInput from 'components/specific/tickets/TicketMessageInput'
import DateSeparator from './DateSeparator'

const TicketContainer = ({ ticketId }) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(true)
  const [openCloseOption, setOpenCloseOption] = useState(false)
  const [acceptTicket, setAcceptTicket] = useState(false)
  const [messages, setMessages] = useState()
  const replyMenuState = useState(null)
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [expand, setExpand] = useState(null)
  const [messageSelected, setMessageSelected] = useState([])

  const containerMessageRef = useRef(null)

  const { state, dispatch, actionMessagesTypes, actionSelectedTicketTypes, actionTicketTypes, actionRepliedMessage } = useTickets()

  useEffect(() => {
    ticketId && fetchTicket(ticketId)
    setOpenCloseOption(false)

    return () => {
      dispatch({ type: actionMessagesTypes.RESET_MESSAGES })
      dispatch({
        type: actionRepliedMessage.REMOVE_REPLY,
      })
      replyMenuState[1](null)
      handleCancel()
    }
  }, [ticketId])


  const fetchTicket = async (id) => {
    try {
      setLoading(true)

      const [{ data: ticket }, { data: messages }] = await Promise.all([showTicketInfo(id), showTicketMessagesList(id, { page_size: 1000 })])

      const { data } = await markMessageRead(id)

      const groupedMessages = messages.results.reduce((acc, curr) => {
        const key = format(curr.created_at, 'dd/MM/yyyy')

        acc[key] ? acc[key].push(curr) : (acc[key] = [{ ...curr, key }])

        return acc
      }, {})

      data?.ticket_data.status === state.current_status &&
        dispatch({
          type: actionTicketTypes.UPDATE_TICKET,
          payload: data?.ticket_data,
        })

      dispatch({
        type: actionMessagesTypes.LOAD_MESSAGES,
        payload: groupedMessages,
      })

      dispatch({
        type: actionSelectedTicketTypes.LOAD_SELECTED_TICKET,
        payload: ticket,
      })

      setMessages(messages.results)
    } catch {
      notify.error('Não foi possível carregar mensagens do contato.')
    } finally {
      setLoading(false)
    }
  }

  const handlerUpdateTicket = async (id, values) => {
    try {
      setLoading(true)

      const { data } = await updateTicket(id, values)

      dispatch({ type: actionSelectedTicketTypes.UPDATE_SELECTED_TICKET, payload: data })

      values.send_satisfaction_survey
        ? notify.success(
          'Conversa encerrada e pesquisa de satisfação enviada ao contato com sucesso!',
        )
        : notify.success('Conversa encerrada com sucesso!')

      setOpenCloseOption(false)
    } catch {
      notify.error('Não foi possível atualizar conversa.')
    } finally {
      setLoading(false)
    }
  }

  const handleSelectedForwards = (messageId) => {
    const newMessageSelected = [...messageSelected]
    const idx = newMessageSelected.indexOf(messageId)
    if (idx !== -1) {
      newMessageSelected.splice(idx, 1)
    } else {
      newMessageSelected.push(messageId)
    }
    setMessageSelected(newMessageSelected)
  }

  const handleCancel = () => {
    setMessageSelected([])
    setShowCheckbox(false)
  }

  const handleForwardsContactsClick = async (contacts) => {
    try {
      setLoading(true)

      const { data } = await forwardMessages({
        connection: state.selected_ticket.connection.id,
        contacts,
        ticket_messages: messageSelected.sort((a, b) => a - b)
      })
      if (contacts.length === 1) {
        const idx = data.tickets.findIndex(item => item.contact.id === contacts[0])
        if (idx !== -1) {
          navigate(`/tickets/${data.tickets[idx].id}`)
        }
      }
      handleCancel()
    } catch {
      notify.error('Não foi possível encaminhar as mensagens.')
    } finally {
      setLoading(false)
    }
  }

  const handleClickCopySelected = () => {
    let text = ''
    messageSelected.forEach((messageId) => {
      const message = messages.find((m) => m.id === messageId);
      if (message.body) {
        if (text) {
          text = `${text}\n\n${message.body.text}`
        } else {
          text = message.body.text
        }
      }
    })

    navigator.clipboard.writeText(text);
    handleCancel()
    notify.success('Mensagens copiadas com sucesso!')
  }

  const handleRightClick = (e) => e.preventDefault();

  return (
    <Row width='100%'>
      <Column position='relative' width='100%'>
        {!showCheckbox &&
          <TicketHeader
            loading={loading}
            ticket={state?.selected_ticket}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            openCloseOption={openCloseOption}
            setOpenCloseOption={setOpenCloseOption}
            acceptTicket={acceptTicket}
            setAcceptTicket={setAcceptTicket}
          />}
        {showCheckbox &&
          <HeaderSelectedMessage
            onClickCopySelected={handleClickCopySelected}
            onCancel={handleCancel}
            messageSelected={messageSelected}
            onForwardsContactsClick={handleForwardsContactsClick}
          />
        }
        {loading ? (
          <SkeletonMessages />
        ) : (
          <Column
            background='#FAFAFB'
            borderTop='none'
            alignItems='center'
            width='100%'
            height='100%'
            overflow='hidden'
            onContextMenu={handleRightClick}
          >
            {openCloseOption && (
              <CloseTicketHeader
                ticket={state.selected_ticket}
                setOpenCloseOption={setOpenCloseOption}
                handlerUpdateTicket={handlerUpdateTicket}
                loading={loading}
              />
            )}
            <Column ref={containerMessageRef} width='100%' flexDirection='column-reverse' height='80%' overflow='auto'>
              {Object.keys(state.messages)
                .sort((itemA, itemB) => formatDateLabel(itemB) - formatDateLabel(itemA))
                .map((date, idx) => (
                  <Column key={`${date}_${idx}`} position='relative' p='10px 10px 0'>
                    {state?.selected_ticket?.closed_at && (
                      <CustomMessage
                        message={{
                          text: `*Conversa foi finalizada*\n${format(state?.selected_ticket?.closed_at, 'dd/MM/yyyy - HH:mm')}`,
                          message_type: 'text',
                          sender_type: 'internal',
                        }}
                      />
                    )}
                    <Column flexDirection='column-reverse' gap='8px'  >
                      {state.messages[date].map((message, index) => (
                        <TicketMessage
                          setExpand={setExpand}
                          showCheckbox={showCheckbox}
                          onSelectedForwards={handleSelectedForwards}
                          onClickForward={() => setShowCheckbox(true)}
                          key={`${message?.id}_${index}`}
                          containerMessageRef={containerMessageRef}
                          index={index}
                          replyMenuState={replyMenuState}
                          selectedticket={state?.selected_ticket}
                          message={message}
                          dispatch={dispatch}
                          messageSelected={messageSelected}
                          actionRepliedMessage={actionRepliedMessage}
                        />
                      ))}
                      <DateSeparator date={date} />
                    </Column>
                  </Column>
                ))}
            </Column>
            <TicketMessageInput state={state} dispatch={dispatch} setAcceptTicket={setAcceptTicket} />
          </Column>
        )}
      </Column>
      {openDrawer && <TicketContactDrawer ticket={state.selected_ticket} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} loading={loading} />}
      {acceptTicket && (
        <ModalAcceptTicket
          loading={loading}
          setLoading={setLoading}
          ticket={acceptTicket}
          open={acceptTicket}
          dispatch={dispatch}
          actionTypes={actionSelectedTicketTypes}
          onClose={() => setAcceptTicket(false)}
        />
      )}
      {expand && <ModalExpandImage closeClickOut open={expand} onClose={() => setExpand(null)} data={expand} />}
    </Row>
  )
}

export default TicketContainer
