import { useState, useEffect } from 'react'
import {
  Column,
  Row,
  Button,
  Checkbox,
  Popover,
  Avatar,
  Typography,
  Input,
  Skeleton
} from 'design-system'
import { colors } from 'configs'
import { useContactsReducer, actionTypes } from '../../../pages/Contacts/store'
import { showContactsList } from 'services'
import { notify } from 'helpers'
import { useDebounce } from 'hooks'

const PopoverForwardsContacts = ({
  elementRef,
  open,
  onForwardsContactsClick,
  ...props
}) => {
  const [search, setSearch] = useState('')
  const [selectedContact, setSelectedContact] = useState([])
  const [loading, setLoading] = useState(false)
  const [state, dispatch] = useContactsReducer()
  const [pagination, setPagination] = useState({
    count: 0,
    next: null,
    previus: null,
    page_size: 9999,
    page: 1,
    total_pages: 1,
  })
  const searchDebounce = useDebounce(search, 1500)

  useEffect(() => {
    if (open) {
      fetch(searchDebounce)
    }
  }, [pagination?.page, searchDebounce, open])

  const fetch = async (search) => {
    try {
      setLoading(true);
      const { data } = await showContactsList({
        ...pagination,
        search,
        can_receive_service_messages: 'True'
      })
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data.results,
      })

      delete data.results

      setPagination({ ...data })
      setLoading(false)
    } catch {
      setLoading(false)
      notify.error('Não foi possível resgatar lista de contatos.')
    }
  }

  const handleSetContact = (contactId) => {
    const newSelectedContact = [...selectedContact]
    const idx = newSelectedContact.indexOf(contactId)
    if (idx !== -1) {
      newSelectedContact.splice(idx, 1)
    } else {
      newSelectedContact.push(contactId)
    }
    setSelectedContact(newSelectedContact)
  }

  const handleForwardsContactsClick = () => onForwardsContactsClick(selectedContact)

  return (
    <Popover ref={elementRef} open={open} width='275px' p='16px' {...props}>
      <Column gap='8px'>
        <Typography color='grey.300' fontWeight='600'>Encaminhar para...</Typography>
        <Input
          width='100%'
          placeholder='Pesquisar'
          type='search'
          value={search}
          onChange={(event) => setSearch(event.target.value.toLowerCase())}
          icon='Search'
          iconPosition='left'
        />
        <Typography fontSize='12px'>
          Apenas contatos que interagiram nas 
          últimas 24h aparecem para encaminhamento.
        </Typography>
        {selectedContact.length > 0 &&
          <Button
            onClick={handleForwardsContactsClick}
            sizeVariants='md'
            width='100%'
          >
            Encaminhar
          </Button>}

        <Column height="196px" overflow='auto' gap='8px'>
          {loading &&
            <Column gap='8px' >
              <Skeleton height='58px' />
              <Skeleton height='58px' />
              <Skeleton height='58px' />
            </Column>
          }
          {!loading && state.contacts?.map((contact, index) => (
            <Row
              key={index}
              p='8px'
              border={`1px solid ${colors.grey[50]}`}
              borderRadius='8px'
              alignItems='center'
              justifyContent='space-between'
              gap='8px'
              className='cursor-pointer'
              onClick={() => handleSetContact(contact.id)}
            >
              <Row alignItems='center' gap='8px' justifyContent='space-between'>
                <Avatar alt={contact?.name} src={contact.src} />
                <Typography fontWeight='600' ellipsis notSelectable>
                  {contact.name}
                </Typography>
              </Row>
              <Checkbox checked={selectedContact.filter(item => item === contact.id).length > 0} />
            </Row>
          ))}
        </Column>
      </Column>
    </Popover>
  )
}

export default PopoverForwardsContacts
