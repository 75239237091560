import { theme } from 'configs'

import Chart from 'react-apexcharts'

const GraphicArea = ({
  title,
  series,
  categories,
  optionsProps,
  ...props
}) => {
  const options = {
    chart: {
      id: title,
    },
    stroke: {
      curve: 'smooth',
    },
    markers: {
      size: 7,
    },
    xaxis: {
      categories,
    },
    yaxis: [
      {
        axisBorder: {
          show: true,
          color: theme.colors.grey[300],
        },
        labels: {
          style: {
            colors: theme.colors.grey[300],
          },
        },
      },
    ],
    ...optionsProps,
  }

  return <Chart type='area' options={options} series={series} {...props} />
}

GraphicArea.defaultProps = {
  title: 'ChatGDS-Graphic',
  labels: [],
  series: [],
  optionsProps: { colors: [theme.colors.primary] },
}

export default GraphicArea
