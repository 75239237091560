import { useState, useEffect } from 'react'
import { theme } from 'configs'

const useWindowResize = () => {
  const {
    responsiveConfigs: { mobileWidth, tabletWidth },
  } = theme
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < mobileWidth,
    isTablet:
      window.innerWidth <= tabletWidth && window.innerWidth > mobileWidth,
    isDesktop: window.innerWidth > tabletWidth,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < mobileWidth,
        isTablet:
          window.innerWidth <= tabletWidth && window.innerWidth > mobileWidth,
        isDesktop: window.innerWidth > tabletWidth,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }

    
  }, [])

  return windowSize
}

export default useWindowResize
