import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Row,
  Button,
  Typography,
  Column,
  Select,
} from 'design-system'

import { createExtraField, showExtraFieldInfo, updatedExtraField } from 'services'
import { notify } from 'helpers'
import { fieldTypesOptions } from 'configs'

const ModalExtraFields = ({ onClose, info, dispatch, actionTypes, ...props }) => {
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (info?.id) {
      handleInfo()
    }

    reset({
      name: '',
      field_type: fieldTypesOptions[0],
    })

    return () => {
      reset({
        name: '',
        field_type: fieldTypesOptions[0],
      })
    }
    
  }, [info])

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      values.field_type = values.field_type.field_type

      const { data } = info?.id
        ? await updatedExtraField(info?.id, values)
        : await createExtraField(values)

      dispatch({
        type: actionTypes.UPDATE_ITEM,
        payload: data,
        tab: 'extra_fields',
        filter: 'name',
      })
      notify.success(
        info?.id
          ? 'Campo personalizado salvo com sucesso.'
          : 'Novo campo personalizado criado com sucesso.',
      )
      onClose()
    } catch {
      notify.error('Não foi possível salvar configuração de campo personalizado.')
    } finally {
      setLoading(false)
    }
  }

  const handleInfo = async () => {
    try {
      setLoading(true)
      const { data } = await showExtraFieldInfo(info?.id)

      data.field_type = fieldTypesOptions.find((f) => f.field_type === data.field_type)

      reset(data)
    } catch (err) {
      if (err.response.data.name[0]) {
        setError('name', 'O nome do campo já existe. Escolha outro, por favor.')
      }
      notify.error('Não foi possível buscar dados do campo personalizado.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal size='sm' open={info} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          {info?.id ? 'Editar campo personalizado' : 'Novo campo personalizado'}
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Column gap='16px'>
            <Row width='100%'>
              <Input
                label='Nome do campo personalizado'
                placeholder='Digite'
                error={errors?.name}
                disabled={loading}
                {...register('name', { required: true })}
              />
            </Row>
            <Row width='100%'>
              <Select
                label='Tipo de campo personalizado'
                options={fieldTypesOptions}
                name='field_type'
                control={control}
                required
                keys={{ label: 'label', value: 'field_type' }}
              />
            </Row>
          </Column>
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalExtraFields
