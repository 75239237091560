import { AxiosResponse } from 'axios';
import { clientApi } from 'providers';
import { Department, DepartmentListParams, DepartmentSelected, ShowDepartmentsListSelectParams } from './types';
import { PaginatedResponse } from '../../types/generic.types';

export const showDepartmentInfo = (id: number): Promise<AxiosResponse<Department>> =>
  clientApi.get(`/departments/${id}/`);

export const showDepartmentsList = (params: Partial<DepartmentListParams>): Promise<AxiosResponse<PaginatedResponse<Department>>> =>
  clientApi.get('/departments/', { params });

export const showDepartmentsListSelect = (params: ShowDepartmentsListSelectParams): Promise<AxiosResponse<DepartmentSelected[]>> =>
  clientApi.get('/departments/select/', { params });

export const createDepartment = (data: Partial<Department>): Promise<AxiosResponse<Department>> =>
  clientApi.post('/departments/', data);

export const updatedDepartment = (id: number, data: Partial<Department>): Promise<AxiosResponse<Department>> =>
  clientApi.put(`/departments/${id}/`, data);

export const deleteDepartment = (id: number): Promise<AxiosResponse<void>> =>
  clientApi.delete(`/departments/${id}/`);