import { colors } from 'configs'
import { ColorChip, Column, Row, Typography } from 'design-system'
import { hexToRGBA } from 'helpers'

const TicketTabItem = ({ active, label, count, ...props }) => {
  return (
    <Column
      width='100%'
      p='8px'
      borderBottom={`1px solid ${active ? '#5E54A9' : '#EBEEF1'}`}
      alignItems='center'
      className='cursor-pointer'
      {...props}
    >
      <Row alignItems='center' gap='8px'>
        <Typography
          whiteSpace='nowrap'
          cursor='pointer'
          fontWeight={active ? '600' : '400'}
          color={active ? 'primary' : 'text'}
          notSelectable
        >
          {label}
        </Typography>
        {count > 0 && (
          <ColorChip
            width='20px'
            height='20px'
            alignItems='center'
            justifyContent='center'
            backgroundColor={hexToRGBA(colors.purple[100], 0.5)}
          >
            <Typography color='white' fontWeight='700' fontSize='10px' textAlign='center'>
              {count > 99 ? '99+' : count}
            </Typography>
          </ColorChip>
        )}
      </Row>
    </Column>
  )
}

TicketTabItem.defaultProps = {
  label: 'Label',
  icon: null,
}

export default TicketTabItem
