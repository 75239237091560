import { AxiosResponse } from 'axios';
import { clientApi } from 'providers';
import { Tag, TagListParams } from './types';
import { PaginatedResponse } from 'types/generic.types';

export const showTagInfo = (id: number): Promise<AxiosResponse<Tag>> => 
  clientApi.get(`/contacts/tags/${id}/`);

export const showTagsList = (params: TagListParams): Promise<AxiosResponse<PaginatedResponse<Tag>>> => 
  clientApi.get('/contacts/tags/', { params });

export const createTag = (data: Omit<Tag, 'id'>): Promise<AxiosResponse<Tag>> => 
  clientApi.post('/contacts/tags/', data);

export const updatedTag = (id: number, data: Partial<Omit<Tag, 'id'>>): Promise<AxiosResponse<Tag>> => 
  clientApi.put(`/contacts/tags/${id}/`, data);

export const deleteTag = (id: number): Promise<AxiosResponse<void>> => 
  clientApi.delete(`/contacts/tags/${id}/`);